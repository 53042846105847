// eslint-disable-next-line eslint-comments/disable-enable-pair
/* eslint-disable jsx-a11y/control-has-associated-label */
import React, { useRef } from 'react';
import { useLocation } from '@reach/router';
import { graphql } from 'gatsby';
import Icon from '@brighthr/component-icon';
import { nanoid } from 'nanoid';
import Container from '../components/Container';
import DefaultLayout from '../layouts/Default';
import PersonProfile from '../components/CMSBlocks/PersonProfile';
import SEO from '../components/SEO';
import FilterButtons from '../components/FilterButtons';
import Peter from '../images/Peter-Done-hero.png';
import Hero from '../components/CMSBlocks/Hero';
import useFilter from '../hooks/useFilter';

const OurPeople = ({ data }) => {
	const allEntries = data.allDatoCmsOurPeoplePage.nodes;
	const ref = useRef();
	const pageContent = useRef(null);

	const { updateFilter, changeFilter, filters, parsedEntries, resetFilters, filteredEntries } =
		useFilter({
			allEntries,
			multiOption: 'associatedCountries',
			ref,
		});

	const location = useLocation();

	return (
		<>
			<SEO path={location.pathname} title="Our People" />
			<Hero bgImg={Peter} title="Our People" fullWidth />
			<section>
				<Container className="!px-0 md:px-unset">
					<div className="my-4 text-center md:pb-2 lg:pt-8">
						<h2 className="w-full text-2xl font-normal text-center md:text-3xl ">
							The faces behind our story
						</h2>
						<p className="hidden md:block">
							We bring experts in every relevant discipline to each client’s
							challenge. The value they deliver goes deeper than their resumes.
						</p>
					</div>
					<div
						className="relative flex flex-col-reverse lg:pt-24 lg:flex-row"
						ref={pageContent}
					>
						<div className="w-full lg:w-3/4 md:pt-0">
							<div
								className="flex flex-row w-full pt-2 pb-4 my-3 overflow-x-scroll overflow-y-hidden md:pb-8 md:overflow-x-auto md:grid md:grid-cols-3 md:my-0 md:py-0 md:gap-y-6 md:gap-x-4 snap-mandatory snap-x scroll-smooth"
								ref={ref}
							>
								{filteredEntries.map(
									(person) =>
										!person.hideOurPeople && (
											<PersonProfile
												info={person}
												className="border-2 snap-center group border-secondary-100 md:border-none"
												key={nanoid()}
											/>
										)
								)}
							</div>
							{filteredEntries?.length === 0 && (
								<div className="flex flex-col items-center justify-center mb-8">
									<h2 className="text-xl md:text-2xl">No results.</h2>
									<button
										type="button"
										onKeyDown={resetFilters}
										onClick={resetFilters}
										className="text-brand-200"
									>
										Clear filters and search again
									</button>
								</div>
							)}
							<div className="flex justify-center px-4 md:hidden ">
								<button
									type="button"
									// eslint-disable-next-line no-return-assign
									onClick={() => (ref.current.scrollLeft += -300)}
									className="p-2 mx-2 bg-white rounded-full shadow-xl"
								>
									<Icon
										size={32}
										className="fill-secondary-300 active:fill-brand-100 focus:active:fill-brand-100"
										iconName="chevron-thin-left"
									/>
								</button>
								<button
									type="button"
									// eslint-disable-next-line no-return-assign
									onClick={() => (ref.current.scrollLeft += 300)}
									className="p-2 mx-2 bg-white rounded-full shadow-xl"
								>
									<Icon
										size={32}
										className="fill-secondary-300 active:fill-brand-100 focus:active:fill-brand-100"
										iconName="chevron-thin-right"
									/>
								</button>
							</div>
						</div>
						<aside className="w-full px-4 py-0 bg-white lg:sticky lg:w-1/4 md:shadow-none md:h-fit md:px-2 md:my-8 md:mt-2 md:overflow-hidden top-16 md:top-20">
							<div className="z-30 flex flex-col-reverse pb-2 pointer-events-auto lg:flex-col md:bg-transparent">
								<FilterButtons
									className="lg:block lg:my-4"
									changeFilter={changeFilter}
									filters={filters}
									allEntries={parsedEntries}
									updateFilter={updateFilter}
									resetFilters={resetFilters}
								/>
							</div>
						</aside>
					</div>
				</Container>
			</section>
		</>
	);
};

export const query = graphql`
	query OurPeople {
		allDatoCmsOurPeoplePage(sort: { fields: position, order: ASC }) {
			nodes {
				...DatoCMSOurPeopleFragment
			}
		}
	}

	fragment DatoCMSOurPeopleFragment on DatoCmsOurPeoplePage {
		personName
		slug
		company
		companyPosition
		companyPositionFullTitle
		spokesperson
		hideOurPeople
		associatedCountries
		schema
		seoMetaTags {
			...GatsbyDatoCmsSeoMetaTags
		}
		personImage {
			gatsbyImageData(
				layout: CONSTRAINED
				imgixParams: { auto: "compress" }
				height: 357
				width: 357
			)
		}
		personBioNode {
			childMdx {
				body
			}
		}
		personEmail
		personTwitter
		personTwitterHandle
	}
`;

const WrappedOurPeople = (props) => (
	<DefaultLayout fullWidth>
		<OurPeople {...props} />
	</DefaultLayout>
);

export default WrappedOurPeople;
